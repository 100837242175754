.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  min-height: 32px;
}

.dropbtn {
  text-align: left;
  padding-left: 10px;
  color: #999999;
  font-size: 16px;
  cursor: pointer;
  border: none;
  height: 30px;
  width: 100%;
  vertical-align: middle;
  background-color: transparent;
}

.dropbtn.disabled {
  background-color: #f2f2f2;
}

.dropbtn.has-error {
  border: solid 1px red;
}

.dropbtn span {
  width: 80%;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.dropbtn:hover, .dropbtn:focus {
  border-color: #66ccff;
}

.custom-dropdown input {
  font-size: 14px;
  background-repeat: no-repeat;
  padding: 5px 5px 5px 10px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #ddd;
  z-index: 9999;
  width: 100%;
}

.dropdown-content .list {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  border: none;
}

.dropdown-content option {
  cursor: pointer;
  color: black;
  padding: 5px 5px 5px 10px;
  text-decoration: none;
  display: block;
  font-weight: normal;
  font-size: 14px;
  border: none;
}

.dropdown-content option:hover {
  background-color: #e6f7ff;
}

.dropdown-content.show {
  display: block;
}

.custom-dropdown .icon {
  display: inline;
  margin: 7px 5px 0 -25px;
}

.custom-dropdown .clear {
  display: inline;
  margin: 7px 5px 0 -15px;
}

.custom-dropdown .clear-single {
  display: inline;
  margin: 7px 5px 0 -25px;
}

.custom-dropdown:hover > div > div .list-inline-item.clear {
  display: inline-block;
}

.custom-dropdown:hover > div .list-inline-item.clear-single {
  display: inline;
}

.custom-dropdown:hover > div > div .icon.clear-icon, .custom-dropdown:hover > div .icon.clear-single-icon {
  display: none;
}

.list-inline-item.clear, .list-inline-item.clear-single {
  display: none;
}

.icon.clear-icon {
  display: inline-block;
}